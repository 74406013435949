<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('globalTrans.market_name') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
          <b-form  @submit.prevent="handleSubmit(searchData)" >
            <b-row>
              <b-col class="col-lg-6">
                <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('org_pro_division.division')"
                  label-for="division_id"
                >
                <b-form-select
                    plain
                    v-model="search.division_id"
                    :options="divisionList"
                    id="division_id"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
                </b-form-group>
              </b-col>
              <b-col  class="col-lg-6">
                <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('org_pro_district.district')"
                  label-for="district_id"
                  >
                  <b-form-select
                    plain
                    v-model="search.district_id"
                    :options="districtList"
                    id="district_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                </b-form-group>
              </b-col>
               <b-col  class="col-lg-6">
                <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('org_pro_upazilla.upazilla')"
                  label-for="upazila_id"
                  >
                  <b-form-select
                    plain
                    v-model="search.upazila_id"
                    :options="upazilaList"
                    id="upazila_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="6" md="6">
                <ValidationProvider name="market Name" vid="market_name">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="market_name"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('globalTrans.market_name')}}
                    </template>
                    <b-form-input
                      id="market_name"
                      v-model="search.market_name"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-marketdback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col class="col-sm-2">
                <b-button type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('globalTrans.market_name') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(organization)="data">
                      {{ getOrgName(data.item.org_id) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status === 1 ? $t('globalTrans.active') : $t('globalTrans.inactive') }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button title="Active/Inactive" variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import FormV from './Form'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { marketNameList, marketToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import commonAuth from '../CommonAuth'

export default {
  mixins: [ModalBaseMasterList, commonAuth],
  components: {
    ValidationObserver,
    ValidationProvider,
    FormV
  },
  data () {
    return {
      search: {
        market_name: '',
        division_id: 0,
        district_id: 0,
        upazila_id: 0
      },
      districtList: [],
      upazilaList: []
    }
  },
  computed: {
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('globalTrans.market_name') + ' ' + this.$t('globalTrans.entry') : this.$t('globalTrans.market_name') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('globalTrans.division'), class: 'text-center' },
        { label: this.$t('globalTrans.district'), class: 'text-center' },
        { label: this.$t('globalTrans.upazila'), class: 'text-center' },
        { label: this.$t('globalTrans.market_name'), class: 'text-left' },
        { label: this.$t('globalTrans.Market') + ' ' + this.$t('globalTrans.code'), class: 'text-left' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'market_name_bn' },
          { key: 'market_code' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'district_name' },
          { key: 'upazilla_name' },
          { key: 'market_name' },
          { key: 'market_code' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.loadData()
  },
  methods: {
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      return upazilaList.filter(upazila => upazila.district_id === districtId)
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      return districtList.filter(district => district.division_id === divisionId)
    },
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(licenseRegistrationServiceBaseUrl, marketToggleStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(licenseRegistrationServiceBaseUrl, marketNameList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response.data))
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      return data.data.map(item => {
        const divisionObject = divisionList.find(division => division.value === item.division_id)
        const districtObject = districtList.find(district => district.value === item.district_id)
        const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.upazila_id)
        const divisionData = {
          division_name: typeof divisionObject !== 'undefined' ? divisionObject.text_en : '',
          division_name_bn: typeof divisionObject !== 'undefined' ? divisionObject.text_bn : ''
        }
        const districtData = {
          district_name: typeof districtObject !== 'undefined' ? districtObject.text_en : '',
          district_name_bn: typeof districtObject !== 'undefined' ? districtObject.text_bn : ''
        }
        const upazillaData = {
          upazilla_name: typeof upazilaObject !== 'undefined' ? upazilaObject.text_en : '',
          upazilla_name_bn: typeof upazilaObject !== 'undefined' ? upazilaObject.text_bn : ''
        }
        return Object.assign({}, item, divisionData, districtData, upazillaData)
      })
    },
    getOrgName (orgId) {
      const tmpOrg = this.organizationList.find(org => org.value === orgId)
      return this.$i18n.locale === 'en' ? tmpOrg !== undefined ? tmpOrg.text_en : '' : tmpOrg !== undefined ? tmpOrg.text_bn : ''
    }
  }
}
</script>
